import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'question1',
    loadChildren: () => import('./question1/question1.module').then( m => m.Question1PageModule)
  },
  {
    path: '',
    redirectTo: 'question1',
    pathMatch: 'full'
  },
  {
    path: 'question1',
    loadChildren: () => import('./question1/question1.module').then( m => m.Question1PageModule)
  },
  {
    path: 'question4',
    loadChildren: () => import('./question4/question4.module').then( m => m.Question4PageModule)
  },
  {
    path: 'question5',
    loadChildren: () => import('./question5/question5.module').then( m => m.Question5PageModule)
  },
  {
    path: 'question6',
    loadChildren: () => import('./question6/question6.module').then( m => m.Question6PageModule)
  },
  {
    path: 'question7',
    loadChildren: () => import('./question7/question7.module').then( m => m.Question7PageModule)
  },
  {
    path: 'question8',
    loadChildren: () => import('./question8/question8.module').then( m => m.Question8PageModule)
  },
  {
    path: 'answerinfo',
    loadChildren: () => import('./answerinfo/answerinfo.module').then( m => m.AnswerinfoPageModule)
  },
  {
    path: 'ceo-officers',
    loadChildren: () => import('./ceo-officers/ceo-officers.module').then( m => m.CeoOfficersPageModule)
  },
  {
    path: 'secretary-officers',
    loadChildren: () => import('./secretary-officers/secretary-officers.module').then( m => m.SecretaryOfficersPageModule)
  },
  {
    path: 'cfo-officers',
    loadChildren: () => import('./cfo-officers/cfo-officers.module').then( m => m.CfoOfficersPageModule)
  },
  {
    path: 'directors',
    loadChildren: () => import('./directors/directors.module').then( m => m.DirectorsPageModule)
  },
  {
    path: 'add-director',
    loadChildren: () => import('./add-director/add-director.module').then( m => m.AddDirectorPageModule)
  },
  {
    path: 'view-director',
    loadChildren: () => import('./view-director/view-director.module').then( m => m.ViewDirectorPageModule)
  },
  {
    path: 'other-person',
    loadChildren: () => import('./other-person/other-person.module').then( m => m.OtherPersonPageModule)
  },
  {
    path: 'add-other-person',
    loadChildren: () => import('./add-other-person/add-other-person.module').then( m => m.AddOtherPersonPageModule)
  },
  {
    path: ':entityNumber',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
